<template>
  <b-container id="error-404">
    <h1>{{ $t('errors.404-notfound-title') }}</h1>
    <p>{{ $t('errors.404-notfound-message') }}</p>
    <p>
      <a href="/">{{ $t('errors.goHome-link') }}</a>
    </p>
  </b-container>
</template>

<script>
export default {
  name: 'Error404NotFound'
}
</script>
